import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { Observable, Subject } from "rxjs";


interface Message {
    channel: string;
    data: any;
}

@Injectable()
export class DataService {
    private message$: Subject<Message>

    apiclientuser: string = "thirdpartyloymark_admin@loymark.com";
    baseServerUrl: string = "https://cuponeraapidev.loymark.com/api";

    // Google Signin
    public ClientID: string = "109187343732-2q5i1698p6c6a9ptqepku2b04jflk2rj.apps.googleusercontent.com";
    public Secret: string = "aF3WctHoLenHitStZH0ETQnZ";

    constructor(private http: HttpClient) {
    }

    GetToken(projectid) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + "/coupons/GetToken";

            try {
                _this.http.post(endpoint, {
                    "UserName": "thirdpartyloymark_admin@loymark.com",
                    "Password": "abc1234"
                }, { }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }

    ValidateToken(token) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/validatetoken';

            let h = new HttpHeaders().set('Authorization', "Bearer " + token);

            try {
                _this.http.post(endpoint, { }, { headers:h }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }

    ValidateOfferCode(token, offercode) {
        let _this = this;
        return new Promise((resolve, reject) => {
            try {
                let endpoint = this.baseServerUrl + "/coupons/ValidateCoupon";
                let h = new HttpHeaders().set('Authorization', 'Bearer ' + token);
                this.http.post(endpoint, {
                    "PromotionCode": offercode,
                    "UserName": _this.apiclientuser
                  }, { headers: h }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }

    GetCoupons(token, page, pageSize, searchText, categoryId) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/coupons/GetCouponList';

            let h = new HttpHeaders().set('Authorization', "Bearer " + token);

            let body = {
                "UserName": _this.apiclientuser,
                "CategoryID": categoryId
            };

            try {
                _this.http.post(endpoint, body, { headers:h }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }

    GetCoupon(token, couponid) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/coupons/GetCouponById';

            let h = new HttpHeaders().set('Authorization', "Bearer " + token);

            let body = {
                "CouponId": couponid, 
                "UserName": _this.apiclientuser
            };

            try {
                _this.http.post(endpoint, body, { headers:h }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }

    GetCategories(token) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/coupons/GetCouponCategoryList';

            let h = new HttpHeaders().set('Authorization', "Bearer " + token);

            let body = {
                "UserName": _this.apiclientuser
            };

            try {
                _this.http.post(endpoint, body, { headers:h }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }

    ClaimCoupon(token, clientId, couponId) {
        let _this = this;

        return new Promise(async (resolve, reject) => {
            let body = {
                "ClientID": clientId,
                "CouponID": couponId,
                "UserName": _this.apiclientuser
            };

            let h = new HttpHeaders().set('Authorization', "Bearer " + token);

            const endpoint = _this.baseServerUrl + '/coupons/ClaimCoupon';

            console.log(endpoint, body);

            try {
                _this.http.post(endpoint, body, { headers:h }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }
}
