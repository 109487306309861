import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
 
export class CacheItem {
    id: string = "";
    data: any = null;
}

export class CacheSearchResult {
    idx: number = -1;
    c: CacheItem = null;
}

@Injectable()
export class CacheService {
    private cache: CacheItem[] = new Array<CacheItem>();

    ExistsInCache(id) {
        
    }

    storeCache(id, data) {
        localStorage.setItem(id, data);
    }
 
    getFromCache(id) {
        return localStorage.getItem(id);
    }

    removeFromCache(id) {
        localStorage.removeItem(id);
    }

    PullFullCache() {
        // Only for debugging pourposes.
        return this.cache;
    }
}