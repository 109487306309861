import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
	public focus;
	public listTitles: any[];
	public location: Location;
	public medicName: string = "";

	searchText: string = "";

	constructor(location: Location, private element: ElementRef, private router: Router) {
		this.location = location;
	}

	onSubmit(e, ctrl) {
		if (e["code"] === "Enter") {
			console.log(this.searchText);
		}
	}

	ngOnInit() {
		this.listTitles = ROUTES.filter(listTitle => listTitle);
	}

	getTitle(){
		var titlee = this.location.prepareExternalUrl(this.location.path());
		if(titlee.charAt(0) === '#'){
			titlee = titlee.slice( 1 );
		}

		for(var item = 0; item < this.listTitles.length; item++){
			if(this.listTitles[item].path === titlee){
				return this.listTitles[item].title;
			}
		}
		return 'Dashboard';
	}

	DoLogout() {
		localStorage.removeItem("medico");
		localStorage.removeItem("authtoken");
		this.router.navigate(['/login']);
	}

}
