import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';


@Component({
	selector: 'singlepage-layout',
	templateUrl: './singlepage-layout.component.html',
	styleUrls: ['./singlepage-layout.component.scss']
})
export class SinglePageLayoutComponent implements OnInit, OnDestroy {
	constructor(private router: Router) { 
		
	}

	async ngOnInit() {
		var html = document.getElementsByTagName("html")[0];
		html.classList.add("auth-layout");
		var body = document.getElementsByTagName("body")[0];
		body.classList.add("bg-default");
	}
	ngOnDestroy() {}
	async ngAfterViewInit() {}
}
