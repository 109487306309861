import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
		path: string;
		title: string;
		icon: string;
		class: string;
}
export const ROUTES: RouteInfo[] = [
	{ path: '/dashboard', title: 'Dashboard',  icon: '', class: '' },
	// { path: '/createcoupon', title: 'Crear Cupón',  icon:'', class: '' },
	{ path: '/coupons', title: 'Estadísticas',  icon:'', class: '' },
	{ path: '/list/todos', title: 'Listado de Cupones',  icon:'', class: '' },
	// { path: '/newfet', title: 'Asignar Cupón',  icon:'', class: '' }
];

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

	public menuItems: any[];
	public isCollapsed = true;

	constructor(private router: Router) { }

	ngOnInit() {
		this.menuItems = ROUTES.filter(menuItem => menuItem);
		this.router.events.subscribe((event) => {
			this.isCollapsed = true;
	 	});
	}

	DoLogout() {
		localStorage.removeItem("medico");
		localStorage.removeItem("authtoken");
		this.router.navigate(['/login']);
	}
}
